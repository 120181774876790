import React, { useState } from "react";

import Sidebar from "../partials/Sidebar";
import Header from "../partials/Header";
import DashboardAvatars from "../partials/dashboard/DashboardAvatars";
import Banner from "../partials/Banner";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";

import { getPositionsTransactions, closePosition, getOrdersTransactions, cancelOrder, getPriceOrderInfo, createOrder, getAccountInfo } from "../serives/backendService";
import Select from "react-select";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import moment from "moment";
import { FaArrowRight, FaArrowLeft, FaList } from "react-icons/fa";

class NovelTrading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfTransactions: [],
      listOfTransactionsFilter: [],
      listPositionsTransactions: [],
      buttonPressed: "today",
      sidebarOpen: false,
      asset_default: { "label": "ALL", "value": "ALL" },
      asset_list: [{ "label": "ALL", "value": "ALL" }],
      listOrdersTransactions: [],
      showModal: false,
      transactionTypes: [{ "label": "Limit", "value": "Limit" }, { "label": "Stop", "value": "Stop" }, { "label": "Market", "value": "Market" }],
      symbolTypes: [{ "label": "Silver", "value": "XAGUSD" }, { "label": "Gold", "value": "XAUUSD" }],
      orderTypes: [{ "label": "Buy", "value": "Buy" }, { "label": "Sell", "value": "Sell" }],
      
      transactionType: { "label": "Market", "value": "Market" },
      symbol: { "label": "Silver", "value": "XAGUSD" },
      orderBuySell: { "label": "Buy", "value": "Buy" },
      price: 0,
      accountBalance: 0,
      accountAvailable: 0,
      totalAlloc: 0,
      order: {
        amount: 0,
        pct: 0,
        ask: 0,
        bid: 0,
        mid: 0,
        cashAvailable: 0,
        cashAfterTransaction: 0,
        orderSize: 0,
        spreadCost: 0,
        lastUpdated: "",
        displayOrderPrice: false,
        isRed: false,
        oldPrice: 0
      }

    };
    this.runStatusTimer = null
    this.runStatusTimerModal = null
    this.closeTransaction = this.closeTransaction.bind(this);
    this.closeOrder = this.closeOrder.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.onFundUsedChange = this.onFundUsedChange.bind(this);
    this.onSymbolChanged = this.onSymbolChanged.bind(this);
    this.onTransactionTypeChanged = this.onTransactionTypeChanged.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.startTimer = this.startTimer.bind(this)
    this.pauseTimer = this.pauseTimer.bind(this)
    this.resumeTimer = this.resumeTimer.bind(this)
    this.createOrder = this.createOrder.bind(this)
    
    
  }

  createOrder(){
    createOrder(this.state.transactionType.value, "Buy", this.state.symbol.value, this.state.totalAlloc, this.state.price).then( () => {
      this.setState({ ...this.state, showModal: false})

    }).catch((e) => { console.log(e); });
  }


  onTransactionTypeChanged(e){
    var s = e
    
    var order = {
      ...this.state.order,
      updatePrice: true,
      displayOrderPrice: s.value == "Market" ? false : true
    }
    this.setState({ ...this.state, order: order, price: 0, transactionType: { value: s.value, label: s.label }}, () => {
      this.pauseTimer();
      getPriceOrderInfo(s.value, this.state.symbol.value, this.state.totalAlloc, this.state.price).then( (x) => {
        var order = {...this.state.order, 
            amount: x['sumInvested'],
            ask: x['ask'],
            bid: x['bid'],
            mid: x['mid'],
            cashAvailable: x['CashAvailableForTrading'],
            cashAfterTransaction: x['CashLeft'],
            orderSize: x['units'],
            spreadCost: x['margin'],
            lastUpdated: x['LastUpdated']
        }
        
        console.log(this.state.order)
        this.setState({ ...this.state, order: order, price: x['bid']});
        this.startTimer();
      }).catch((e) => { console.log(e); this.startTimer(); });
    })
  }

   onSymbolChanged(e) {
      var s = e
      var order = {
        ...this.state.order,
       
        updatePrice: true,
      }
      this.setState({ ...this.state, order: order,  symbol: { value: s.value, label: s.label },
        price: 0}, () => {
        this.pauseTimer();
        getPriceOrderInfo(this.state.transactionType.value, s.value, this.state.totalAlloc, this.state.price).then( (x) => {
          var order = {...this.state.order, 
              amount: x['sumInvested'],
              ask: x['ask'],
              bid: x['bid'],
              mid: x['mid'],
              cashAvailable: x['CashAvailableForTrading'],
              cashAfterTransaction: x['CashLeft'],
              orderSize: x['units'],
              spreadCost: x['margin'],
              lastUpdated: x['LastUpdated'],
             
          }
          
          console.log(this.state.order)
          this.setState({ ...this.state, order: order,  price: x['bid']});
          this.startTimer();
        }).catch((e) => { console.log(e); this.startTimer(); });
      })
    }


  onFundUsedChange(event) {
    var totalAlloc = event.target.value;
    this.setState({ ...this.state, totalAlloc: totalAlloc });
  }

  onPriceChange(event){
    var price = Number(event.target.value)
    var isRed = false;
    if(this.state.transactionType.value=="Stop" && price < this.state.order.bid) isRed = true;
    if(this.state.transactionType.value=="Limit" && price > this.state.order.bid) isRed = true;
    
    var order = {
      ...this.state.order,
      isRed: isRed
    }
    this.setState({ ...this.state, order: order,  price: price });
  }

  // if(this.state.order.transactionType.value=="Stop" && price < this.state.order.bid) price = this.state.order.bid
  //   if(this.state.order.transactionType.value=="Limit" && price > this.state.order.bid) price = this.state.order.bid

  handleOutsideClick = (e) => {
    if (e.target.id === "modalOverlay") {
      this.toggleModal();
    }
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  closeOrder(orderID) {
    cancelOrder(orderID).then(x => {
    }).catch((e) => { console.log(e) })
  }

  closeTransaction(transactionID) {
    console.log(transactionID)
    closePosition(transactionID).then(x => {
    }).catch((e) => { console.log(e) })
  }

  changeAsset(asset) {
    this.setState({ ...this.state, asset_default: asset });
    this.filterData(this.state.buttonPressed, "")
  }

  setSidebarOpen(statusLocal) {
    console.log("Executed!!!")
    console.log(this.state.sidebarOpen)
    this.setState({ ...this.state, sidebarOpen: statusLocal })
  }

  startTimer = () => {
    if (this.runStatusTimerModal == null) {
      this.runStatusTimerModal = setInterval(() => {
        if (this.state.showModal) {
          getPriceOrderInfo(
            this.state.transactionType?.value,
            this.state.symbol?.value,
            this.state.totalAlloc,
            this.state.price
          )
            .then((x) => {
              let order = {
                ...this.state.order,
                amount: x["sumInvested"],
                ask: x["ask"],
                bid: x["bid"],
                mid: x["mid"],
                cashAvailable: x["CashAvailableForTrading"],
                cashAfterTransaction: x["CashLeft"],
                orderSize: x["units"],
                spreadCost: x["margin"],
                lastUpdated: x["LastUpdated"],
              };
  
              // if (this.state.price === 0) {
              //   order.price = x["bid"];
              // }
  
              console.log(order);
              this.setState({...this.state,  order: order });
            })
            .catch((e) => console.log(e));
        }
      }, 2000);
    }
  };
  
  pauseTimer = () => {
    if (this.runStatusTimerModal) {
      clearInterval(this.runStatusTimerModal);
      this.runStatusTimerModal = null; // Reset to allow restarting later
    }
  };
  
  resumeTimer = () => {
    this.startTimer(); // Just call startTimer to resume
  };

  componentDidMount() {
    getPositionsTransactions().then(x => {
      this.setState({ ...this.state, listPositionsTransactions: x })
    }).catch((e) => { console.log(e) });
    getOrdersTransactions().then(x => {
      this.setState({ ...this.state, listOrdersTransactions: x });
    }).catch((e) => { console.log(e) });
    getAccountInfo()
    .then((res) => {
      this.setState({ ...this.state, accountBalance: res.TotalValue, accountAvailable: res.CashBalance - res.MarginNetExposure });
    })
    .catch(() => {});

    if (this.runStatusTimer == null)
      this.runStatusTimer = setInterval(() => {
        getPositionsTransactions().then(x => {
          this.setState({ ...this.state, listPositionsTransactions: x });
        }).catch((e) => { console.log(e) });
        getOrdersTransactions().then(x => {
          this.setState({ ...this.state, listOrdersTransactions: x });
        }).catch((e) => { console.log(e) });
        getAccountInfo()
        .then((res) => {
          this.setState({ ...this.state, accountBalance: res.TotalValue, accountAvailable: res.CashBalance - res.MarginNetExposure });
        })
        .catch(() => {});
    

      }, 2000)

     this.startTimer();
  }

  componentWillUnmount() {
    if (this.runStatusTimer != null)
      clearInterval(this.runStatusTimer)
    this.runStatusTimer = null;
    this.pauseTimer();
  }

  render() {
    return (
      <>
        <div className="flex h-screen overflow-hidden">
          {/* Sidebar */}
          <Sidebar sidebarOpen={this.state.sidebarOpen} setSidebarOpen={() => this.setSidebarOpen(false)} />

          {/* Content area */}
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {/*  Site header */}
            <Header setSidebarOpen={() => this.setSidebarOpen(true)} />
            <main>


              <div class="px-0 " style={{ background: "black" }}>
                <div class="px-0 mx-auto">
                  <div class="flex flex-row content-center self-center bg-blue">
                    <div style={{ height: "50px", width: "60px", overflow: "hidden" }} className="ml-6">
                      <img style={{ objectFit: "fill" }} className="w-full" src={process.env.PUBLIC_URL + '/dashboardlogo.png'} alt="Login" />
                    </div>

                    <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-2xl tracking-wider ml-4  text-blue-900 font-bold uppercase justify-center self-center ">Trading</span>
                  </div>
                </div>
              </div>
              <div className="flex m-2 items-center mb-2">
                {/* Left: Avatars */}
                {/* <DashboardAvatars /> */}
                <button
                    type="Create"
                    className="px-4 py-2 bg-green-600 text-white rounded mr-4"
                    onClick={x => { this.setState({ ...this.state, showModal: true }); }}
                  >
                    Create Order
                  </button>
                  <span class="ml-8">
                  <h4 class="text-gray-600 text-center font-bold">Balance (Total)</h4>
                  <h4 class="pt-2  text-center">
                      <NumberFormat value={this.state?.accountBalance?.toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                  </h4>
                  </span>
                  <span class="ml-8">
                  <h4 class="text-gray-600 text-center font-bold">Available</h4>
                  <h4 class="pt-2  text-center">
                      <NumberFormat value={this.state?.accountAvailable?.toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                  </h4>
                  </span>
                {/* Right: Actions */}
              </div>
              <row>
                <section style={{ fontFamily: "Roboto !important" }} class="bg-gray-700">

                  <div class="mx-auto">
                    <div class="flex text-white mb-2">
                      <div>
                        <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Positions</span>
                      </div>
                      <div class="ml-auto">
                      </div>
                    </div>
                    <div class="bg-white">
                      <div class="w-full overflow-x-auto">
                        <table class="text-left w-full min-w-max">
                          <thead class="flex w-full">
                            <tr class="text-xs text-white bg-gray-800 text-left flex w-full">
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Close</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Open DT</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Symbol</th>
                              {/* <th class="p-4 w-1/4 font-medium text-center font-bold">Status</th> */}
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Open</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">P/L</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">P/L</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Qty</th>

                              <th class="p-4 w-1/4 font-medium text-center font-bold">Allocated</th>
                            </tr>
                          </thead>
                          <tbody class="flex flex-col items-center justify-between overflow-y-scroll w-full" style={{ maxHeight: "70vh" }}>
                            {this.state.listPositionsTransactions?.map((item, index) => (
                              <tr class={`text-xs ${index % 2 ? "bg-white-50" : "bg-gray-50"}  flex w-full`}>
                                <td class="p-4 w-1/4 text-center mt-4">
                                  <button style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} class=" font-medium text-white bg-red-500 hover:bg-red-600 rounded transition duration-200 p-2 w-full" onClick={x => this.closeTransaction(item.sourceOrder)}>
                                    Close
                                  </button>
                                </td>
                                <td class="p-4 w-1/4 text-center mt-4">
                                  <Moment format="DD/MM/YY HH:mm:ss">{item.orderdt}</Moment>
                                </td>
                                <td class="p-4 w-1/4">
                                  <div class="text-center">
                                    {/* <img class="w-8 h-8 rounded-md m-auto" src={`/cryptocurrency-icons/128/color/${ String(item.symbol).replace("USDT","").toLowerCase()}.png`} alt="" /> */}
                                    <p class="font-medium text-center mt-4">{item.symbol}</p>
                                  </div>
                                </td>
                                {/* <td class="p-4 w-1/4">
                              <div class="text-center">
                                <p class="font-medium text-center mt-4">{item.status}</p>
                              </div>
                            </td> */}
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.open).toFixed(4)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.profitcurr).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.profitpct).toFixed(2)} displayType={"text"} thousandSeparator={true} suffix={"%"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.qty).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={""} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.allocated).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>

                  <br></br>
                </section>
              </row>
              <row>
                <section style={{ fontFamily: "Roboto !important" }} class="bg-gray-700">

                  <div class="mt-4 mx-auto">
                    <div class="flex text-white mb-2">
                      <div>
                        <span style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} className="text-sm tracking-wider ml-4 mb-4 font-bold uppercase justify-center self-center ">Orders</span>
                      </div>
                      <div class="ml-auto">

                      </div>

                    </div>
                    <div class="bg-white">
                      <div class="w-full overflow-x-auto">
                        <table class="text-left w-full min-w-max">
                          <thead class="flex w-full">
                            <tr class="text-xs text-white bg-gray-800 text-left flex w-full">
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Cancel</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Order DT</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Symbol</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Qty</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Price</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Allocated</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">B/S</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Status</th>
                              <th class="p-4 w-1/4 font-medium text-center font-bold">Type</th>
                            </tr>
                          </thead>
                          <tbody class="flex flex-col items-center justify-between overflow-y-scroll w-full" style={{ maxHeight: "70vh" }}>
                            {this.state.listOrdersTransactions?.map((item, index) => (
                              <tr class={`text-xs ${index % 2 ? "bg-white-50" : "bg-gray-50"}  flex w-full`}>
                                <td class="p-4 w-1/4 text-center mt-4">
                                  <button style={{ fontFamily: "Roboto,arial,sans-serif", color: "white" }} class=" font-medium text-white bg-red-500 hover:bg-red-800 rounded transition duration-200 p-2 w-full" onClick={x => this.closeOrder(item.orderID)}>
                                    Cancel
                                  </button>
                                </td>
                                <td class="p-4 w-1/4 text-center mt-4">
                                  <Moment format="DD/MM/YY HH:mm:ss">{item.orderdt}</Moment>
                                </td>
                                <td class="p-4 w-1/4">
                                  <div class="text-center">
                                    {/* <img class="w-8 h-8 rounded-md m-auto" src={`/cryptocurrency-icons/128/color/${ String(item.symbol).replace("USDT","").toLowerCase()}.png`} alt="" /> */}
                                    <p class="font-medium text-center mt-4">{item.symbol}</p>
                                  </div>
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.qty).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={""} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>

                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.price).toFixed(4)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <NumberFormat value={Number(item.allocated).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <p class="font-medium text-center ">{item.buysell}</p>
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <p class="font-medium text-center ">{item.status}</p>
                                </td>
                                <td class="p-4 w-1/4 font-medium text-center mt-4 ">
                                  <p class="font-medium text-center ">{item.type}</p>
                                </td>


                              </tr>
                            ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </section>
              </row>
            </main>
            <Banner />
          </div>
        </div>

        {/* Modal */}
        {this.state.showModal && (
          <div id="modalOverlay" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={this.handleOutsideClick}>
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-xl font-bold mb-4">Place Order</h2>
            
                <div className="mb-2">
                  <label className="block text-sm font-medium">Order Type</label>
                  <Select className="" styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: 'bg-slate-300',
                      width: '150px'
                    }),
                  }}
                    value={this.state.transactionType} style={{ width: "300px" }} options={this.state.transactionTypes}  onChange={this.onTransactionTypeChanged}/>
                  <label className="block text-sm font-medium mt-2">Asset</label>
                  <Select className="" styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: 'bg-slate-300',
                      width: '150px'
                    }),
                  }}
                    value={this.state.symbol} style={{ width: "300px" }} options={this.state.symbolTypes} onChange={this.onSymbolChanged} />

                  <label className="block text-sm font-medium mt-2">Amount</label>
                  <div class="flex ">
                    
                    <div class="w-3/4 mt-2">
                      <p class="mb-1 text-xs text-indigo-500 font-medium">
                        <div class="">
                          <span class="text-xl">$</span>
                          <input
                            onChange={this.onFundUsedChange} 
                            class="shadow  appearance-none border rounded text-right ml-4 py-2 px-1 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline w-40 " id="password" min={0} max={300000} type="number" value={this.state.totalAlloc}
                          // value={this.state.fundUsedPct}
                          ></input>
                        </div>
                      </p>
                    </div>
                    <div class="flex items-center w-1/4 ">
                      <div class="flex mb-2">
                        <h3 class="text-sm text-gray-600">
                          <NumberFormat value={Number(this.state.order.amount).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                        </h3>
                      </div>
                    </div>
                  </div>

                  <label className="block text-sm font-small mt-2">
                    <div class="flex items-center  px-4 ">
                    <p className="mr-2">Ask:</p> 
                    <NumberFormat className="flex inline ml-4"  value={Number(this.state.order.ask).toFixed(4)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    <p className="flex inline ml-4"></p>
                    <p className="mr-2">Bid:</p> 
                    <NumberFormat className="flex inline ml-4"  value={Number(this.state.order.bid).toFixed(4)}  displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    <p className="flex inline ml-4"></p>
                   
                    </div>
                    <div class="flex items-center  px-4 ">
                    <p className="mr-2">Mid:</p> 
                    <NumberFormat className="flex inline"  value={Number(this.state.order.mid).toFixed(4)}  displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    </div>
                  </label>

                  <label className="block text-sm font-small mt-2">
                    <div class="flex items-center  px-4 ">
                    <p className="mr-2">Cash Available:</p> 
                    <NumberFormat className="flex inline" value={Number(this.state.order.cashAvailable).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    </div>
                  </label>
                  <label className="block text-sm font-small mt-2">
                    <div class="flex items-center px-4 ">
                    <p className="mr-2"> Cash After Transaction:</p> 
                    <NumberFormat className="flex inline"  value={Number(this.state.order.cashAfterTransaction).toFixed(2)} displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    </div>
                  </label>
                  <label className="block text-sm font-small mt-2">
                  <div class="flex items-center px-4 ">
                    <p className="mr-2">  Order Size:</p> 
                    <NumberFormat className="flex inline"  value={Number(this.state.order.orderSize).toFixed(2)} displayType={"text"} thousandSeparator={true} suffix={" units"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    </div>
                    
                  </label>
                  <label className="block text-sm font-small mt-2">
                  <div class="flex items-center px-4 ">
                    <p className="mr-2">  Spread Cost:</p> 
                    <NumberFormat className="flex inline"   value={Number(this.state.order.spreadCost).toFixed(2)}  displayType={"text"} thousandSeparator={true} prefix={"$"} renderText={(formattedValue) => <div>{formattedValue}</div>} />
                    </div>
                    
                  </label>
                  
                </div>

                <div className="mb-2">
                <label className="block text-sm font-medium">Last Updated</label>
                <Moment format="DD/MM/YY HH:mm:ss">{this.state.order.lastUpdated}</Moment>

                </div>
                {this.state.order.displayOrderPrice ?  <div className="mb-2">
                  <label className="block text-sm font-medium">Order Price</label>
                  <input
                    // onChange={this.onFundUsedChange}
                    // onChange={this.onFundUsedChange} 
                    class={`shadow appearance-none border rounded text-center ml-4 p-3 py-2 px-1 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline w-40 ${ this.state.order.isRed ? "bg-red-500" : ""}`} min={0} max={100000} step="0.0001" type="number"
                  value={this.state.price}
                  onChange={this.onPriceChange} 
                  ></input>
                </div> : <></>
                }

                <div className="flex justify-between">
                  <button
                    type="Cancel"
                    className="px-4 py-2 bg-gray-500 text-white rounded"
                    onClick={x => { this.setState({ ...this.state, showModal: false }); }}
                  >
                    Close
                  </button>
                  { (this.state.order.orderSize > 0 && this.state.order.cashAfterTransaction > 0)&&
                  <button
                   onClick={this. createOrder}
                    className="px-4 py-2 bg-green-600 text-white rounded"
                  >
                    Create
                  </button>}
                </div>
             
            </div>
          </div>
        )}
      </>


    );
  }
}

export default NovelTrading;
