import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {  getUsername } from "../serives/backendService";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split("/")[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const userName = getUsername();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust the width value according to your requirement
    };

    handleResize(); // Check on initial render
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebarOpen || sidebar.current?.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="lg:w-64 p-0">
      {/* Sidebar backdrop (mobile only) */}
      

      {/* Sidebar */}
      <div style={{background:"#029fdf", padding:"0px"}} id="sidebar" ref={sidebar} className={`absolute z-40 p-0 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0  p-4 transition-transform duration-200 ease-in-out ${sidebarOpen ? "translate-x-0" : "-translate-x-64"}`}>
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 p-0">
          {/* Close button */}
         
          {/* Logo */}
          <NavLink exact to="/" className="block">
            <div style={{height: "100px", overflow: "hidden"}}>
            <img style={{objectFit: "fill"}} className="w-full mb-4" src={process.env.PUBLIC_URL + '/logosidebar.jpeg'} alt="Login" />
            </div>
          
          </NavLink>
        </div>

        {/* Links */}
        <div className="w-full">
          
          <ul className="mt-3">
            {/* Dashboard */}
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "" && "bg-blue-900"}`}>
              <NavLink exact to="/" className={`block text-gray-200  hover:text-white transition duration-150 ${page === "" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-400 ${page === "" && "text-indigo-500"}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                    <path className={`fill-current text-gray-600 ${page === "" && "text-indigo-600"}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                    <path className={`fill-current text-gray-400 ${page === "" && "text-indigo-200"}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                  </svg>
                  <span style={{fontFamily: "SabicHeadlineRegular,arial,sans-serif;"}} className="text-sm tracking-wider font-bold uppercase">Dashboard</span>
                </div>
              </NavLink>
            </li>
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "rlbot" && "bg-blue-900"}`}>
              <NavLink exact to="/rlbot" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "users" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "rlbot" && "text-indigo-500"}`} d="M13 7H7v6h6V7z" />
                    <path className={`fill-current text-gray-400 ${page === "rlbot" && "text-indigo-500"}`} fill-rule="evenodd" d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">Backtrack</span>
                </div>
              </NavLink>
            </li>

            {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "pricepredictions" && "bg-blue-900"}`}>
              <NavLink exact to="/pricepredictions" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "pricepredictions" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "pricepredictions" && "text-indigo-500"}`} d="M13 7H7v6h6V7z" />
                    <path className={`fill-current text-gray-400 ${page === "pricepredictions" && "text-indigo-500"}`} fill-rule="evenodd" d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm font-medium">Price Predictions</span>
                </div>
              </NavLink>
            </li>
             */}
            
            {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "predictions" && "bg-blue-900"}`}>
              <NavLink exact to="/predictions" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "predictions" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-400 ${page === "predictions" && "text-indigo-300"}`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
                    <path className={`fill-current text-gray-700 ${page === "predictions" && "text-indigo-600"}`} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
                    <path className={`fill-current text-gray-600 ${page === "predictions" && "text-indigo-500"}`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
                  </svg>
                  <span className="text-sm font-medium">Prediction Chart</span>
                </div>
              </NavLink>
            </li> */}
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "sentiment" && "bg-blue-900"}`}>
              <NavLink exact to="/sentiment" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "sentiment" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "sentiment" && "text-indigo-500"}`} d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z" />
                    <path className={`fill-current text-gray-400 ${page === "sentiment" && "text-indigo-300"}`} d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">Sentiment Social</span>
                </div>
              </NavLink>
            </li>

            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "sentimentNews" && "bg-blue-900"}`}>
              <NavLink exact to="/sentimentNews" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "sentimentNews" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "sentimentNews" && "text-indigo-500"}`} d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                    <path className={`fill-current text-gray-400 ${page === "sentimentNews" && "text-indigo-300"}`} d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">Sentiment News</span>
                </div>
              </NavLink>
            </li>

            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "tradingbot" && "bg-blue-900"}`}>
              <NavLink exact to="/tradingbot" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "users" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "tradingbot" && "text-indigo-500"}`} d="M13 7H7v6h6V7z" />
                    <path className={`fill-current text-gray-400 ${page === "tradingbot" && "text-indigo-500"}`} fill-rule="evenodd" d="M7 2a1 1 0 012 0v1h2V2a1 1 0 112 0v1h2a2 2 0 012 2v2h1a1 1 0 110 2h-1v2h1a1 1 0 110 2h-1v2a2 2 0 01-2 2h-2v1a1 1 0 11-2 0v-1H9v1a1 1 0 11-2 0v-1H5a2 2 0 01-2-2v-2H2a1 1 0 110-2h1V9H2a1 1 0 010-2h1V5a2 2 0 012-2h2V2zM5 5h10v10H5V5z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">Trading Bot</span>
                </div>
              </NavLink>
            </li>

            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "transactionhistory" && "bg-blue-900"}`}>
              <NavLink exact to="/transactionhistory" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "transactionhistory" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "transactionhistory" && "text-indigo-500"}`} d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                    <path className={`fill-current text-gray-400 ${page === "transactionhistory" && "text-indigo-500"}`} fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">History</span>
                </div>
              </NavLink>
            </li>

            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "GPT" && "bg-blue-900"}`}>
              <NavLink exact to="/GPT" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "GPT" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "GPT" && "text-indigo-500"}`} d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"/>
                    <path className={`fill-current text-gray-400 ${page === "GPT" && "text-indigo-500"}`} fill-rule="evenodd" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">FinanceGPT</span>
                </div>
              </NavLink>
            </li>



            { userName == "rahat" &&
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "users" && "bg-blue-900"}`}>
              <NavLink exact to="/users0987654321k4In" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "users" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "users" && "text-indigo-500"}`} d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                    <path className={`fill-current text-gray-400 ${page === "users" && "text-indigo-500"}`} fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                  </svg>
                  <span className="text-sm tracking-wider font-bold uppercase">Users</span>
                </div>
              </NavLink>
            </li>
            }

          { userName == "rahat" &&
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "trading" && "bg-blue-900"}`}>
              <NavLink exact to="/novelTrading123qeasp" className={`block text-gray-200 hover:text-white transition duration-150 ${page === "users" && "hover:text-gray-200"}`}>
                <div className="flex flex-grow">
                  <svg className="flex-shrink-0 h-7 w-7 mr-3" viewBox="0 0 24 24">
                    <path className={`fill-current text-gray-600 ${page === "trading" && "text-indigo-500"}`} d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                    <path className={`fill-current text-gray-400 ${page === "trading" && "text-indigo-500"}`} fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                  </svg> 
                  <span className="text-sm tracking-wider font-bold uppercase">Trading</span>
                </div>
              </NavLink>
            </li>
            }


            {/*          <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${page === "users" && "bg-gray-900"}`}>
              <NavLink exact to="/users" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'users' && 'hover:text-gray-200'}`}>
                <div className="flex flex-grow">
                <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                      <path className={`fill-current text-gray-600 ${page === 'users' && 'text-indigo-500'}`} d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                      <path className={`fill-current text-gray-400 ${page === 'users' && 'text-indigo-300'}`} d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
                    </svg>
                  <span className="text-sm font-medium">Users</span>
                </div>

             </NavLink>
             </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
